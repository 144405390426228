export const neblSobDict = [
  'Нет',
  'Желудочное кровотечение',
  'Повышенная кровоточивость тканей',
  'Повторный ОИМ',
  'ОНМК',
  'ТИА',
  'Внезапное нарушение ритма сердца',
  'Внезапная смерть',
  'Смерть от других причин',
];
