export const oslozhneniyaOksDict = [
  'Нет',
  'Кардиогенный шок',
  'Отек легких',
  'Фибрилляция желудочков',
  'Иные нарушения ритма',
  'ТЭЛА',
  'Аневризма ЛЖ',
  'ОНМК',
  'ХСН',
  'Летальный исход',
]