import { EntityType } from '@type/config/entity.type';


export const vitaminDPervichnyjEntity: EntityType = {
  key: 'vitamin_d_pervichnyj',
  label: {
    singular: 'Витамин D первичный',
    plural: 'Витамин D первичный',
    genitive: 'Витамин D первичный',
  },
  type: 'one',
  parent: 'card',
  columns: [
    {
      key: '25-oh-d-ng-ml',
      type: 'decimal',
      label: '25(OH)D, нг/мл',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: '1-25-oh-2d-ng-ml',
      type: 'decimal',
      label: '1,25(OH)2D, нг/мл',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
  ],
};
