import { EntityType } from '@type/config/entity.type';


export const ehkhoPosleKagEntity: EntityType = {
  key: 'ehkho_posle_kag',
  label: {
    singular: 'ЭХО после КАГ',
    plural: 'ЭХО после КАГ',
    genitive: 'ЭХО после КАГ',
  },
  type: 'one',
  parent: 'card',
  columns: [
    {
      key: 'fv-lzh',
      type: 'number',
      label: 'ФВ ЛЖ, %',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'stepen-mitralnoj-regurgitacii',
      type: 'select',
      label: 'Степень митральной регургитации',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      variants: [
        'I',
        'II',
        'III',
        'IV',
      ]
    },
    {
      key: 'obem-levogo-predserdiya-ml',
      type: 'decimal',
      label: 'Объем левого предсердия, мл',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'sdla-mm-rt-st',
      type: 'number',
      label: 'СДЛА, мм рт. ст.',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'poperechnyj-razmer-lp-mm',
      type: 'number',
      label: 'Поперечный размер ЛП, мм',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'diametr-levogo-zheludochka-mm',
      type: 'number',
      label: 'Диаметр левого желудочка, мм',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'zony-gipokineza',
      type: 'multiselect',
      label: 'Зоны гипокинеза',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      variants: [
        'Нет',
        'Передняя стенка',
        'Перегородка',
        'Верхушка',
        'Боковая стенка',
        'Нижняя стенка',
      ],
    },
    {
      key: 'zony-akineza',
      type: 'multiselect',
      label: 'Зоны акинеза',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      variants: [
        'Нет',
        'Передняя стенка',
        'Перегородка',
        'Верхушка',
        'Боковая стенка',
        'Нижняя стенка',
      ],
    },
  ],
};
