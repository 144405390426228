import { ConfigType } from '@type/config/config.type';
import { abcdeCherez1MesEntity } from './abcde-cherez-1-mes.entity';
import { abcdeCherez12MesEntity } from './abcde-cherez-12-mes.entity';
import { abcdeCherez3MesEntity } from './abcde-cherez-3-mes.entity';
import { abcdeCherez6MesEntity } from './abcde-cherez-6-mes.entity';
import { cardEntity } from './card.entity';
import { dannyePriVypiskeEntity } from './dannye-pri-vypiske.entity';
import { ehkhoPosleKagEntity } from './ehkho-posle-kag.entity';
import { klinicheskieDannyePriPostupleniiEntity } from './klinicheskie-dannye-pri-postuplenii.entity';
import { koronarografiyaEntity } from './koronarografiya.entity';
import { labDiagnostikaCherez3MesEntity } from './lab-diagnostika-cherez-3-mes.entity';
import { laboratornayaDiagnostikaGospitalizaciyaEntity } from './laboratornaya-diagnostika-gospitalizaciya.entity';
import { mikrornkEntity } from './mikrornk.entity';
import { osmotrCherez1MesEntity } from './osmotr-cherez-1-mes.entity';
import { osmotrCherez12MesEntity } from './osmotr-cherez-12-mes.entity';
import { osmotrCherez3MesEntity } from './osmotr-cherez-3-mes.entity';
import { osmotrCherez6MesEntity } from './osmotr-cherez-6-mes.entity';
import { polimorfizmGenovEntity } from './polimorfizm-genov.entity';
import { urokinazaPervichnayaEntity } from './urokinaza-pervichnaya.entity';
import { vitaminDPervichnyjEntity } from './vitamin-d-pervichnyj.entity';


export const configDcs: ConfigType = {
  appTitle: 'DS39CARDIO',
  entities: [
    cardEntity,
    klinicheskieDannyePriPostupleniiEntity,
    laboratornayaDiagnostikaGospitalizaciyaEntity,
    koronarografiyaEntity,
    ehkhoPosleKagEntity,
    dannyePriVypiskeEntity,
    polimorfizmGenovEntity,
    mikrornkEntity,
    urokinazaPervichnayaEntity,
    vitaminDPervichnyjEntity,

    abcdeCherez1MesEntity,
    osmotrCherez1MesEntity,

    abcdeCherez3MesEntity,
    osmotrCherez3MesEntity,
    labDiagnostikaCherez3MesEntity,

    abcdeCherez6MesEntity,
    osmotrCherez6MesEntity,

    abcdeCherez12MesEntity,
    osmotrCherez12MesEntity
  ],
};
