export const preparatsDict = [
  'Ингибиторы АПФ',
  'Антагонисты РА (сартаны)',
  'Бета-адреноблокаторы',
  'Блокаторы медленных КК',
  'Альфа-адреноблокаторы',
  'Альфа2-адреномиметики центральные',
  'Непетлевые диуретики/тиазид/подобные',
  'Петлевые диуретики (фуросемид/торасемид)',
  'Антагонисты альдостерона',
  'Системные глюкокортикоиды',
  'Антиагреганты  - ацетилсалициловая кислота',
  'Антиагреганты - игибиторы GPIIb/IIIa (клопидогрел/тикагрелор)',
  'НОАК',
  'Непрямые антикоагулянты',
  'Низкомолекулярные гепарины',
  'Нитраты',
  'Статины',
  'Метформин',
  'Глифлозины (ингибиторы SGLT2)',
  'Инсулин',
  'Антиаритмики: соталол/амиодарон',
  'Дигоксин',
  'Триметазидин',
  'ИПП',
  'Другие',
];