import { EntityType } from '@type/config/entity.type';
import { neblSobDict } from './dicts/nebl-sob.dict';
import { preparatsDict } from './dicts/preparats.dict';


export const osmotrCherez3MesEntity: EntityType = {
  key: 'osmotr_cherez_3_mes',
  label: {
    singular: 'Осмотр через 3 месяца',
    plural: 'Осмотр через 3 месяца',
    genitive: 'Осмотр через 3 месяца',
  },
  type: 'one',
  parent: 'card',
  columns: [
    {
      key: 'data-priema',
      type: 'date',
      label: 'Дата приема',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      required: true,
    },
    {
      key: 'rost-sm',
      type: 'number',
      label: 'Рост, см',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'ves-kg',
      type: 'number',
      label: 'Вес, кг',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'okruzhnost-talii-sm',
      type: 'number',
      label: 'Окружность талии, см',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'prekratil-kurenie',
      type: 'boolean',
      label: 'Прекратил курение или уменьшил число сигарет',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'klinicheskie-priznaki-ibs',
      type: 'boolean',
      label: 'Клинические признаки ИБС',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'funkcionalnyj-klass-ibs',
      type: 'select',
      label: 'Функциональный класс ИБС',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      variants: [
        'I',
        'II',
        'III',
        'IV',
      ],
      visibleRule: {
        fieldKey: 'klinicheskie-priznaki-ibs',
        equal: true,
      }
    },
    {
      key: 'fklinicheskie-priznaki-hsn',
      type: 'boolean',
      label: 'Клинические признаки ХСН',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'funkcionalnyj-klass-po-nyha',
      type: 'select',
      label: 'Функциональный класс по NYHA',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      variants: [
        'I',
        'II',
        'III',
        'IV',
      ],
      visibleRule: {
        fieldKey: 'fklinicheskie-priznaki-hsn',
        equal: true,
      }
    },
    {
      key: 'klass-po-strazhesko-vasilenko',
      type: 'select',
      label: 'Класс по Стражеско-Василенко',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      variants: [
        'I',
        'IIA',
        'IIB',
        'III',
      ],
      visibleRule: {
        fieldKey: 'fklinicheskie-priznaki-hsn',
        equal: true,
      }
    },
    {
      key: 'sad-mm-rt-st',
      type: 'number',
      label: 'САД, мм рт. ст.',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'dad-mm-rt-st',
      type: 'number',
      label: 'ДАД, мм рт. ст.',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'chss-ud-min',
      type: 'number',
      label: 'ЧСС, уд/мин',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'prinimaemye-preparaty',
      type: 'multiselect',
      label: 'Принимаемые препараты',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      variants: preparatsDict,
    },
    {
      key: 'neblagopriyatnye-sobytiya',
      type: 'multiselect',
      label: 'Неблагоприятные события',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      variants: neblSobDict,
    },
    {
      key: 'kommentarii',
      type: 'long-text',
      label: 'Комментарии',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
  ],
};
