import { EntityType } from '@type/config/entity.type';


export const labDiagnostikaCherez3MesEntity: EntityType = {
  key: 'lab_diagnostika_cherez_3_mes',
  label: {
    singular: 'Лабораторная диагностика через 3 мес',
    plural: 'Лабораторная диагностика через 3 мес',
    genitive: 'Лабораторная диагностика через 3 мес',
  },
  type: 'one',
  parent: 'card',
  columns: [
    {
      key: 'hb-gemoglobin-g-l',
      type: 'decimal',
      label: 'HB, гемоглобин, г/л',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'rbc-ehritrocity-mln-v-mkl',
      type: 'decimal',
      label: 'RBC (эритроциты), млн в мкл',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'plt-trombocity-tys-v-mkl',
      type: 'number',
      label: 'PLT (тромбоциты), тыс в мкл',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'wbc-lejkocity-tys-v-mkl',
      type: 'decimal',
      label: 'WBC (лейкоциты), тыс в мкл',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'mochevina-mmol-l',
      type: 'decimal',
      label: 'Мочевина, ммоль/л',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'kreatinin-mkmol-l',
      type: 'decimal',
      label: 'Креатинин, мкмоль/л',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'mochevaya-kislota-mkmol-l',
      type: 'decimal',
      label: 'Мочевая кислота, мкмоль/л',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'glyukoza-mmol-l',
      type: 'decimal',
      label: 'Глюкоза, ммоль/л',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'obschij-holesterin-mmol-l',
      type: 'decimal',
      label: 'Общий холестерин, ммоль/л',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'lpnp-mmol-l',
      type: 'decimal',
      label: 'ЛПНП, ммоль/л',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'lpvp-mmol-l',
      type: 'decimal',
      label: 'ЛПВП, ммоль/л',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'trigliceridy-mmol-l',
      type: 'decimal',
      label: 'Триглицериды, ммоль/л',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'fibrinogen-g-l',
      type: 'decimal',
      label: 'Фибриноген, г/л',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
  ],
};
