import { EntityType } from '@type/config/entity.type';


export const koronarografiyaEntity: EntityType = {
  key: 'koronarografiya',
  label: {
    singular: 'Коронарография',
    plural: 'Коронарография',
    genitive: 'Коронарографию',
  },
  type: 'one',
  parent: 'card',
  columns: [
    {
      key: 'tip-krovosnabzheniya',
      type: 'select',
      label: 'Тип кровоснабжения',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      variants: [
        'Левый',
        'Правый',
        'Сбалансированный',
      ]
    },
    {
      key: 'stvol-lka',
      type: 'select',
      label: 'Ствол ЛКА',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      variants: [
        'Нет стеноза',
        'Стеноз до 50%',
        'Стеноз 50-75%',
        'Субтотальная окклюзия',
        'Острая тотальная окклюзия',
      ]
    },
    {
      key: 'pka',
      type: 'select',
      label: 'ПКА',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      variants: [
        'Нет стеноза',
        'Стеноз до 50%',
        'Стеноз 50-75%',
        'Субтотальная окклюзия',
        'Острая тотальная окклюзия',
      ]
    },
    {
      key: 'ogibayuschaya-vetv',
      type: 'select',
      label: 'Огибающая ветвь',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      variants: [
        'Нет стеноза',
        'Стеноз до 50%',
        'Стеноз 50-75%',
        'Субтотальная окклюзия',
        'Острая тотальная окклюзия',
      ]
    },
    {
      key: 'pmzhv',
      type: 'select',
      label: 'ПМЖВ',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      variants: [
        'Нет стеноза',
        'Стеноз до 50%',
        'Стеноз 50-75%',
        'Субтотальная окклюзия',
        'Острая тотальная окклюзия',
      ]
    },
    {
      key: 'vetv-tupogo-kraya',
      type: 'select',
      label: 'Ветвь тупого края',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      variants: [
        'Нет стеноза',
        'Стеноз до 50%',
        'Стеноз 50-75%',
        'Субтотальная окклюзия',
        'Острая тотальная окклюзия',
        'Не визуализирована',
      ]
    },
    {
      key: 'chislo-stentov',
      type: 'number',
      label: 'Число стентов',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'lokalizaciya-stentov',
      type: 'multiselect',
      label: 'Локализация стентов',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      variants: [
        'ЛКА',
        'ПКА',
        'ОВ',
        'ПМЖВ',
        'ВТК',
      ],
    },
    {
      key: 'kommentarii',
      type: 'long-text',
      label: 'Комментарии',
      display: {
        list: false,
        link: false,
        one: false,
        create: true,
        update: true,
      },
      counted: true,
    },
  ],
};
