import { EntityType } from '@type/config/entity.type';


export const mikrornkEntity: EntityType = {
  key: 'mikrornk',
  label: {
    singular: 'МикроРНК',
    plural: 'МикроРНК',
    genitive: 'МикроРНК',
  },
  type: 'one',
  parent: 'card',
  columns: [
    {
      key: 'mikrornk-21',
      type: 'decimal',
      label: 'МикроРНК-21',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'mikrornk-29',
      type: 'decimal',
      label: 'МикроРНК-29',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'mikrornk-143',
      type: 'decimal',
      label: 'МикроРНК-143',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
  ],
};
