import { EntityType } from '@type/config/entity.type';
import { preparatsDict } from './dicts/preparats.dict';


export const klinicheskieDannyePriPostupleniiEntity: EntityType = {
  key: 'klinicheskie_dannye_pri_postuplenii',
  label: {
    singular: 'Клинические данные при поступлении',
    plural: 'Клинические данные при поступлении',
    genitive: 'Клинических данных при поступлении',
  },
  type: 'one',
  parent: 'card',
  columns: [
    {
      key: 'data-obrascheniya-gospitalizacii',
      type: 'date',
      label: 'Дата обращения/госпитализации',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      required: true,
    },
    {
      key: 'dlitelnost-bolevogo-sindroma',
      type: 'select',
      label: 'Длительность болевого синдрома',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      variants: ['менее 1 часа', '1 - 6 часов', '6 - 12 часов', 'более 12 часов'],
    },
    {
      key: 'ehlevaciya-st-na-ehkg',
      type: 'boolean',
      label: 'Элевация ST на ЭКГ',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'ehlevaciya-v-otvedeniyah',
      type: 'multiselect',
      label: 'Элевация в отведениях',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      variants: [
        'I',
        'II',
        'III',
        'AVL',
        'AVR',
        'AVF',
        'V1',
        'V2',
        'V3',
        'V4',
        'V5',
        'V6',
      ],
      visibleRule: {
        fieldKey: 'ehlevaciya-st-na-ehkg',
        equal: true,
      }
    },
    {
      key: 'sao2-pri-postuplenii-pulsoksimetriya',
      type: 'number',
      label: 'SaO2 при поступлении (пульсоксиметрия)',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'rost-sm',
      type: 'number',
      label: 'Рост, см',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'ves-kg',
      type: 'number',
      label: 'Вес, кг',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'okruzhnost-talii-sm',
      type: 'number',
      label: 'Окружность талии, см',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'kurenie-tabaka',
      type: 'select',
      label: 'Курение табака',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      search: true,
      variants: [
        'Курит сейчас',
        'Курил раньше',
        'Не курил(-а) никогда',
      ],
    },
    {
      key: 'stazh-kureniya-let',
      type: 'number',
      label: 'Стаж курения, лет',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      visibleRule: {
        fieldKey: 'kurenie-tabaka',
        oneOf: ['Курит сейчас', 'Курил раньше'],
      },
    },
    {
      key: 'chislo-vykurivaemyh-sigaret-v-den-sht',
      type: 'number',
      label: 'Число выкуриваемых сигарет в день, шт',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      visibleRule: {
        fieldKey: 'kurenie-tabaka',
        oneOf: ['Курит сейчас', 'Курил раньше'],
      },
    },
    {
      key: 'pervichnyj-oim',
      type: 'boolean',
      label: 'Первичный ОИМ',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'predyduschij-oim',
      type: 'select',
      label: 'Предыдущий ОИМ',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      variants: ['Менее 28 дней', 'Менее 3 месяцев', '3 - 6 месяцев', '6 - 12 месяцев', 'Более 12 месяцев или неизвестно (ПИКС)'],
      visibleRule: {
        fieldKey: 'pervichnyj-oim',
        equal: false,
      }
    },
    {
      key: 'stabilnaya-stenokardiya-v-anamneze',
      type: 'boolean',
      label: 'Стабильная стенокардия в анамнезе',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'funkcionalnyj-klass-stenokardii',
      type: 'select',
      label: 'Функциональный класс стенокардии',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      variants: ['I', 'II', 'III', 'IV'],
      visibleRule: {
        fieldKey: 'stabilnaya-stenokardiya-v-anamneze',
        equal: true,
      }
    },
    {
      key: 'nalichie-hsn-v-anamneze',
      type: 'boolean',
      label: 'Наличие ХСН в анамнезе',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'funkcionalnyj-klass-po-nyha',
      type: 'select',
      label: 'Функциональный класс по NYHA',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      variants: ['I', 'II', 'III', 'IV'],
      visibleRule: {
        fieldKey: 'nalichie-hsn-v-anamneze',
        equal: true,
      }
    },
    {
      key: 'arterialnaya-gipertenziya',
      type: 'boolean',
      label: 'Артериальная гипертензия',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'dlitelnost-ag-let',
      type: 'select',
      label: 'Длительность АГ, лет',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      variants: ['менее 5 лет', '5-10 лет', 'более 10 лет или неизвестно'],
      visibleRule: {
        fieldKey: 'arterialnaya-gipertenziya',
        equal: true,
      },
    },
    {
      key: 'stepen-povysheniya-ad',
      type: 'select',
      label: 'Степень повышения АД',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      variants: ['1', '2', '3'],
      visibleRule: {
        fieldKey: 'arterialnaya-gipertenziya',
        equal: true,
      },
    },
    {
      key: 'sad-pri-postuplenii-mm-rt-st',
      type: 'number',
      label: 'САД при поступлении, мм рт. ст.',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'dad-pri-postuplenii-mm-rt-st',
      type: 'number',
      label: 'ДАД при поступлении, мм рт. ст.',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'chss-pri-postuplenii-ud-min',
      type: 'number',
      label: 'ЧСС при поступлении, уд/мин',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'nalichie-u-rodstvennikov-sosudistyh-sobytij',
      type: 'boolean',
      label: 'Наличие в анамнезе у родственников первой линии сосудистых событий (ОИМ, ОНМК, ВСС)',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'hbp-v-anamneze',
      type: 'boolean',
      label: 'ХБП в анамнезе',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'stadiya-hbp',
      type: 'select',
      label: 'Стадия ХБП',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      variants: ['1', '2', '3a', '3b', '4', '5'],
      visibleRule: {
        fieldKey: 'hbp-v-anamneze',
        equal: true,
      }
    },
    {
      key: 'saharnyj-diabet-v-anamneze',
      type: 'boolean',
      label: 'Сахарный диабет в анамнезе',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'tip-sd',
      type: 'select',
      label: 'Тип СД',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      variants: ['I', 'II', 'Иной'],
      visibleRule: {
        fieldKey: 'saharnyj-diabet-v-anamneze',
        equal: true,
      },
    },
    {
      key: 'sroki-verifikacii-diagnoza-sd',
      type: 'select',
      label: 'Сроки верификации диагноза СД',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      variants: ['Менее 1 года', '1 год и более'],
      visibleRule: {
        fieldKey: 'saharnyj-diabet-v-anamneze',
        equal: true,
      },
    },
    {
      key: 'nalichie-hirurgicheskogo-vmeshatelstva',
      type: 'boolean',
      label: 'Наличие в ближайшие 30 дней хирургического вмешательства',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'prinimaemye-preparaty',
      type: 'multiselect',
      label: 'Принимаемые препараты',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      variants: preparatsDict,
    },
    {
      key: 'kommentarii',
      type: 'long-text',
      label: 'Комментарии',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
  ],
};
