import { EntityType } from '@type/config/entity.type';
import { oslozhneniyaOksDict } from './dicts/oslozhneniya-oks.dict';
import { preparatsDict } from './dicts/preparats.dict';


export const dannyePriVypiskeEntity: EntityType = {
  key: 'dannye_pri_vypiske',
  label: {
    singular: 'Данные при выписке',
    plural: 'Данные при выписке',
    genitive: 'Данные при выписке',
  },
  type: 'one',
  parent: 'card',
  columns: [
    {
      key: 'data-vypiski',
      type: 'date',
      label: 'Дата выписки',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      required: true,
    },
    {
      key: 'sad-pri-vypiske-mm-rt-st',
      type: 'number',
      label: 'САД при выписке, мм рт. ст.',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'dad-pri-vypiske-mm-rt-st',
      type: 'number',
      label: 'ДАД при выписке, мм рт. ст.',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'chss-pri-vypiske-ud-min',
      type: 'number',
      label: 'ЧСС при выписке, уд/мин',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'naznachennye-preparaty',
      type: 'multiselect',
      label: 'Назначенные препараты',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      variants: preparatsDict,
    },
    {
      key: 'oslozhneniya-oks',
      type: 'multiselect',
      label: 'Осложнения ОКС',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      variants: oslozhneniyaOksDict,
    },
    {
      key: 'kommentarii',
      type: 'long-text',
      label: 'Комментарии',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
  ],
};
