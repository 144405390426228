import { EntityType } from '@type/config/entity.type';

export const cardEntity: EntityType = {
  key: 'card',
  label: {
    singular: 'Карта',
    plural: 'Карты',
    genitive: 'Карту',
  },
  parent: 'root',
  type: 'list',
  columns: [
    {
      key: 'snils',
      type: 'snils',
      label: 'СНИЛС',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      required: true,
      search: true,
    },
    {
      key: 'familiya',
      type: 'text',
      label: 'Фамилия',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      required: true,
      search: true,
    },
    {
      key: 'imya',
      type: 'text',
      label: 'Имя',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      required: true,
      search: true,
    },
    {
      key: 'otchestvo',
      type: 'text',
      label: 'Отчество',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      search: true,
    },
    {
      key: 'pol',
      type: 'select',
      label: 'Пол',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      search: true,
      variants: ['Мужской', 'Женский'],
    },
    {
      key: 'data-rozhdeniya',
      type: 'date',
      label: 'Дата рождения',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      required: true,
    },
    {
      key: 'adres-mesta-prozhivaniya',
      type: 'text',
      label: 'Адрес места проживания',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'telefon',
      type: 'phone',
      label: 'Телефон',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      required: true,
    },
    {
      key: 'ehlektronnaya-pochta',
      type: 'text',
      label: 'Электронная почта',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      search: true,
    },
  ]
}