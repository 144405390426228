import { EntityType } from '@type/config/entity.type';


export const polimorfizmGenovEntity: EntityType = {
  key: 'polimorfizm_genov',
  label: {
    singular: 'Полиморфизм генов',
    plural: 'Полиморфизм генов',
    genitive: 'Полиморфизм генов',
  },
  type: 'one',
  parent: 'card',
  columns: [
    {
      key: 'polimorfizm-vdpb',
      type: 'select',
      label: 'Полиморфизм VDPB',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      variants: [
        'Не выявлено',
        'Gc1f (rs7041T-rs4588C)',
        'Gc1s (rs7041G-rs4588C)',
        'Gc1s/2 (rs7041G-rs4588A)',
        'Gc2 (rs7041T-rs4588A)',
        'Иной'
      ]
    },
    {
      key: 'polimorfizm-vdr',
      type: 'select',
      label: 'Полиморфизм VDR',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      variants: [
        'Не выявлено',
        'Met1Thr (rs2228570)',
        'Ile352 (TaqI) (rs731236)',
      ]
    },
  ],
};
