import { EntityType } from '@type/config/entity.type';


export const urokinazaPervichnayaEntity: EntityType = {
  key: 'urokinaza_pervichnaya',
  label: {
    singular: 'Урокиназа первичная',
    plural: 'Урокиназа первичная',
    genitive: 'Урокиназа первичная',
  },
  type: 'one',
  parent: 'card',
  columns: [
    {
      key: 'upa-ng-ml',
      type: 'decimal',
      label: 'UPA, нг/мл',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
    {
      key: 'upar-ng-ml',
      type: 'decimal',
      label: 'UPAR, нг/мл',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
    },
  ],
};
