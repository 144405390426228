import { EntityType } from '@type/config/entity.type';


export const abcdeCherez3MesEntity: EntityType = {
  key: 'abcde_cherez_3_mes',
  label: {
    singular: 'ABCDE через 3 месяца',
    plural: 'ABCDE через 3 месяца',
    genitive: 'ABCDE через 3 месяца',
  },
  type: 'one',
  parent: 'card',
  columns: [
    {
      key: 'data-issledovaniya',
      type: 'date',
      label: 'Дата исследования',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      required: true,
    },
    {
      key: 'shag-a-lokalnaya-sokratimost',
      type: 'select',
      label: 'Шаг А, локальная сократимость',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      variants: ['0', '1'],
    },
    {
      key: 'shag-b-peregruzka-legkih-b-linii',
      type: 'select',
      label: 'Шаг В, перегрузка легких, В-линии',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      variants: ['0', '1'],
    },
    {
      key: 'shag-c-sokratitelnyj-rezerv-lzh',
      type: 'select',
      label: 'Шаг С, сократительный резерв ЛЖ',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      variants: ['0', '1'],
    },
    {
      key: 'shag-d-rezerv-skorosti-koronarnogo-krovotoka',
      type: 'select',
      label: 'Шаг D, резерв скорости коронарного кровотока',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      variants: ['0', '1', 'Не измерялся'],
    },
    {
      key: 'shag-e-rezerv-serdechnogo-ritma',
      type: 'select',
      label: 'Шаг Е, резерв сердечного ритма',
      display: {
        list: true,
        link: false,
        one: true,
        create: true,
        update: true,
      },
      counted: true,
      variants: ['0', '1'],
    },
  ],
};
